.public-key {
    word-break: break-all;
    white-space: pre-wrap;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 1rem;
}

.subheader {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

/* Remove all default styles from buttons */
button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

/* Image Uploader styles */
.image-uploader {
    width: 100%;
    height: 200px;
}

.image-uploader.no-image {
    border: 1px dashed #ccc;
}

.image-preview {
    max-width: 200px;
    max-height: 200px;
    border-radius: 1rem;
    margin-right: 1rem;
    border: 1px solid #cccccc;
}

.font-size-1 {
    font-size: 1rem;
}